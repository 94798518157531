import React, { Component } from "react";

class Experience extends Component {
  render() {
    return (
      <section
        className="resume-section p-3 p-lg-5 d-flex flex-column"
        id="experience"
      >
        <div className="my-auto">
          <h2 className="mb-5">Experience</h2>
          <div className="resume-item d-flex flex-column flex-md-row mb-4">
            <div className="resume-content mr-auto w-75">
              <div className="mb-1 h3">JET Squared (Lehigh Valley, PA)</div>
              <div className="mb-2 h4 text-dark">Owner / Lead Engineer</div>
              <p>
                Branching out on my own has been an experience full of
                challenges; through which have helped me learn to be lean and
                agile. This agility has helped JET Squared focus on solutions
                that are <b>client</b> specific. From taking the
                &apos;paper&apos; out of &apos;paperwork&apos; to automating
                business processes to give you more time to work with your
                clients, JET Squared seeks to help you do what you do best.
              </p>
              <p>
                We have all recognized that asking the right questions can be
                half the battle. BUT ..
                <div className="ml-3">
                  <ul className="mb-0">
                    <li>What questions do we ask?</li>
                    <li>Do we know where we can improve?</li>
                    <li>Can this be done more efficiently?</li>
                    <li>
                      I&apos;m sure there&apos;s software that can help us do
                      this, but what is it and how would I go about finding it?
                    </li>
                  </ul>
                </div>
              </p>
              <p>
                Perhaps having a <code>Software Engineer</code> on your staff -
                FOR FREE - could be a great benefit? <br />
                This is where JET Squared can help! Schedule a FREE initial
                consultation <small>( I like coffee )</small>, we can chat about
                your next great idea and map out a plan to accomplish it.
              </p>
              <p>
                Types of companies and individuals we have helped: Small &amp;
                medium businesses, non-profits, churches, daycares, and
                professors in academia.
              </p>
              <p />
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 2018 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-4">
            <div className="resume-content mr-auto w-75">
              <div className="mb-1 h3">Blue Sombrero (Atlanta, GA)</div>
              <div className="mb-2 h4 text-dark">Senior Software Engineer</div>
              <p>
                Part of a team responsible for converting one of our legacy
                apps. New implementations were composed of Angular components &
                services, RESTful APIs, CQRS, and table creation; at times
                requiring SQL profiling and tracing from the legacy app.
                <br />
                <strong className="text-secondary">Major contribution</strong>:
                introduced a data service pattern with async operators (using
                behavior subject) to our Angular project.
              </p>
              <p>
                <strong className="text-secondary">btw</strong> - This job was
                awesome! This team is building incredible enterprise scale apps
                - the right way - while satisfying business requirements. Code
                reviews, process improvement, short feedback loop with
                management, work-life balance.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2017 - May 2018</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-4">
            <div className="resume-content mr-auto w-75">
              <div className="mb-1 h3">MDL Automation (Roswell, GA)</div>
              <div className="mb-2 h4 text-dark">Software Developer</div>
              <p>
                At this SaaS company I was responsible for website management,
                new feature design and implementation. Wrote service layer code
                (NServiceBus) and provided support to our customer service team.
                <br />
                <strong className="text-secondary">Major contribution</strong>:
                Completed an end-to-end solution (Winforms + Windows service +
                Web) for monitoring over 200 nationally distributed computers,
                resulting in a 60% reduction in support staff workload.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">December 2014 - October 2017</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row mb-4">
            <div className="resume-content mr-auto w-75">
              <div className="mb-1 h3">
                Robert L. Wright & Associates, Inc. (Houston, TX)
              </div>
              <div className="mb-2 h4 text-dark">Sr. Draftsman / Associate</div>
              <p>
                This structural engineering firm has worked on projects BIG and
                small. My time here began as a structural draftsman and I
                detailed projects for apartment buildings, home additions,
                additions and improvements to Minute Maid Park (Houston Astros
                Stadium), road design (to handle large equipment), and many
                others. The owner, Robert Wright, has expertise ranging:
                concrete, post-tension concrete, timber, tilt-wall, steel, new
                construction and renovation (I&apos;m sure I&apos;m missing a
                few).
              </p>
              <p>
                In 2008, Hurricane Ike, hit the shores of Texas with a 17ft
                storm surge. Thousands of homes were damaged and hundreds of
                homes were completely destroyed. At this time the firm began
                producing structural forensic reports (SFR), assessing
                structural damages to property, which could be used in legal
                hearings.
              </p>
              <p>
                CHALLENGE: How do we produce these SFR&apos;s fast enough? It
                was taking on average 3 days to produce these reports. I
                pioneered our creation of software to automate project
                workflows: reducing report creation time by 85% (i.e. 3 days to
                3 hours). My drafting responsibilities were greatly reduced
                after 2009 and thereafter I produced internal software programs
                and managed outside developers.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                Mar. &apos;04 - June &apos;05 <br />
                <small>and</small> <br />
                Nov. &apos;08 - Dec. &apos;14
              </span>
            </div>
          </div>
          {/* 
          <div className="resume-item d-flex flex-column flex-md-row">
            <div className="resume-content mr-auto w-75">
              <div className="mb-1 h3">KLG Corporation (Centennial, CO)</div>
              <div className="mb-2 h4 text-dark">CAD Department Manager</div>
              <p>Description coming soon.</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">June 2007 - September 2008</span>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}

export default Experience;
