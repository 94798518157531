import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/james.scss";
// import "./index.css";
import SideNav from "./components/sideNav/sideNav";
import About from "./components/about/about";
import Experience from "./components/experience/experience";
import Education from "./components/education/education";
// import Interests from "./components/interests/interests";
import Skills from "./components/skills/skillsList";
import Work from "./components/work/work";

// export { default as SideNav } from './sideNav/sideNav';
// export { default as About } from './about/about';
// export { default as Experience } from './experience/experience';
// export { default as Education } from './education/education';
// export { default as Interests } from './interests/interests';
// export { default as Skills } from './skills/skillsList';
// export { default as Work } from './work/work';

export default class James extends Component {
  render() {
    // var mobile_fix = '<div className="d-none d-xs-block mobile-fix">&nbsp;</div>';
    return (
      <div className="d-block jetsquaredBody">
        <SideNav />
        <div
          className="container-fluid p-0"
          data-spy="scroll"
          data-target=".navbar"
        >
          <About />
          <Experience />
          <Education />
          <Skills />
          {/* <Interests /> */}
          <Work />
        </div>
      </div>
    );
  }
}
