import React, { Component } from "react";

class Work extends Component {
  render() {
    return (
      <section
        className="resume-section p-3 p-lg-5 d-flex flex-column"
        id="work"
      >
        <h2 className="mb-5">My Work</h2>
        {/* <div className="row">
          <div className="col">
            <img
              src="../../img/FourCorners.jpg"
              alt="FourCorners"
              style="width:100%"
            />
          </div> */}
        {/* <div className="column">
            <img src="img_snow.jpg" alt="Snow" style="width:100%" />
          </div>
          <div className="column">
            <img src="img_mountains.jpg" alt="Mountains" style="width:100%" />
          </div>
          <div className="column">
            <img src="img_lights.jpg" alt="Lights" style="width:100%" />
          </div>
        </div> */}
        <h4>Coming Soon...</h4>
      </section>
    );
  }
}

export default Work;
