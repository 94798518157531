import React, { Component } from "react";
// import { default as skillsItems } from "./skills";

class SkillsItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skillsItems: [
        {
          id: 0,
          category: "frontend",
          aLink:
            "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
          iconName: "devicons devicons-html5"
        },
        {
          id: 1,
          category: "frontend",
          aLink: "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3",
          iconName: "fab fa-css3-alt"
        },
        {
          id: 2,
          category: "frontend",
          aLink: "https://www.javascript.com",
          iconName: "fab fa-js-square"
        },
        {
          id: 3,
          category: "frontend",
          aLink: "https://www.jquery.com",
          iconName: "devicons devicons-jquery"
        },
        {
          id: 4,
          category: "frontend",
          aLink: "https://sass-lang.com/",
          iconName: "devicons devicons-sass"
        },
        {
          id: 5,
          category: "frontend",
          aLink: "https://getbootstrap.com/",
          iconName: "devicons devicons-bootstrap"
        },
        {
          id: 6,
          category: "backend",
          aLink: "https://wordpress.org/",
          iconName: "devicons devicons-wordpress"
        },
        {
          id: 7,
          category: "tools",
          aLink: "https://www.npmjs.com/",
          iconName: "devicons devicons-npm"
        },
        {
          id: 8,
          category: "tools",
          aLink: "https://gulpjs.com/",
          iconName: "devicons devicons-gulp"
        },
        {
          id: 9,
          category: "frontend",
          aLink: "https://reactjs.org/",
          iconName: "fab fa-react"
        },
        {
          id: 10,
          category: "backend",
          aLink: "https://github.com/dotnet/core",
          iconName: "devicons devicons-dotnet"
        },
        {
          id: 11,
          category: "database",
          aLink: "https://www.microsoft.com/en-us/sql-server/sql-server-2017",
          iconName: "devicons devicons-msql_server"
        },
        {
          id: 12,
          category: "backend",
          aLink: "https://www.php.net",
          iconName: "devicons devicons-php"
        },
        {
          id: 40,
          category: "os",
          aLink: "http://releases.ubuntu.com/releases/16.04/",
          iconName: "fab fa-linux"
        },
        {
          id: 13,
          category: "database",
          aLink: "https://www.mongodb.com/",
          iconName: "devicons devicons-mongodb"
        },
        {
          id: 14,
          category: "frontend",
          aLink: "https://angular.io/",
          iconName: "devicons devicons-angular"
        },
        {
          id: 15,
          category: "database",
          aLink: "https://www.mysql.com/",
          iconName: "devicons devicons-mysql"
        },
        {
          id: 16,
          category: "tools",
          aLink: "https://git-scm.com/",
          iconName: "devicons devicons-git"
        },
        {
          id: 17,
          category: "tools",
          aLink: "https://www.atlassian.com/software/jira",
          iconName: "devicons devicons-jira"
        },
        {
          id: 18,
          category: "paas",
          aLink: "https://aws.amazon.com/",
          iconName: "fab fa-aws"
        },
        {
          id: 19,
          category: "tools",
          aLink: "https://bitbucket.org/product",
          iconName: "devicons devicons-bitbucket"
        },
        {
          id: 20,
          category: "paas",
          aLink: "https://www.digitalocean.com/",
          iconName: "fab fa-digital-ocean"
        },
        {
          id: 21,
          category: "os",
          aLink: "https://www.microsoft.com/en-us/windows/features",
          iconName: "fab fa-windows"
        }
      ]
    };
  }
  render() {
    var categories = Array.from(
      // eslint-disable-next-line no-undef
      new Set(this.state.skillsItems.map(a => a.category))
    );
    var itemsByCtgs = categories.map(x => {
      return { [x]: this.state.skillsItems.filter(si => si.category === x) };
    });

    return itemsByCtgs.map(item => {
      var ctg = Object.keys(item)[0];
      return (
        <span key={ctg}>
          <h5>{ctg}</h5>
          {item[ctg].map(skill => {
            return (
              <li key={skill.id} className="list-inline-item">
                <a target="_blank" rel="noopener noreferrer" href={skill.aLink}>
                  <i className={skill.iconName} />
                </a>
              </li>
            );
          })}
        </span>
      );
    });
  }
}

export default SkillsItems;
