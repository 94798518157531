import React, { Component } from "react";
import myResume from "../../static/James_Taylor_II_201903313.pdf";

class SocialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialItems: [
        {
          id: 0,
          aLink: "https://www.linkedin.com/in/jetsquared/",
          iconName: "fa-linkedin"
        },
        {
          id: 1,
          aLink: "https://www.facebook.com/jetaylorii",
          iconName: "fab fa-facebook-f"
        },
        {
          id: 2,
          aLink: "https://twitter.com/jetsquared1",
          iconName: "fa-twitter"
        },
        {
          id: 3,
          aLink: "https://github.com/jetsquared",
          iconName: "fa-github"
        },
        {
          id: 4,
          aLink: { myResume },
          iconName: "far fa-file-pdf",
          download: "James_Taylor_resume"
        }
      ]
    };
  }
  render() {
    return (
      <ul className="list-inline list-social-icons mb-0">
        {this.state.socialItems.map(item => {
          return item.download ? (
            <li key={item.id} className="list-inline-item">
              <a
                target="_blank"
                download={item.download}
                rel="noopener noreferrer"
                href={myResume}
              >
                <span className="fa-stack fa-lg">
                  <i className="fa fa-circle fa-stack-2x" />
                  <i
                    className={`fab ${item.iconName} fa-stack-1x fa-inverse`}
                  />
                </span>
              </a>
            </li>
          ) : (
            <li key={item.id} className="list-inline-item">
              <a target="_blank" rel="noopener noreferrer" href={item.aLink}>
                <span className="fa-stack fa-lg">
                  <i className="fa fa-circle fa-stack-2x" />
                  <i
                    className={`fab ${item.iconName} fa-stack-1x fa-inverse`}
                  />
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default SocialList;
